<template>
  <WsUpdate
    :modelName="modelName"
    :id="_id"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="_pageSettingSecondary"
    titleKey="name"
  >
  </WsUpdate>
</template>

<script>
import S_Stone_Wasa from "@/__vue2stone_cms/service/wasa";
import model from "@/__vue2stone_cms/models/contact_request_notify_mail";
export default {
  metaInfo() {
    return {
      title: `${this.$t("編輯")} ${this.$t(model.label)}`,
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "mail", "remark"],
            },
          ],
        },
      ],
      secondary: [],
    },
  }),
  computed: {
    _pageSettingSecondary() {
      const fields = ["is_active"];
      if (
        this.$config.wsmodule.country_code &&
        S_Stone_Wasa.hasScope(["boss"])
      ) {
        fields.push("country_code");
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>